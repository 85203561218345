<template>
  <div style="width: 1200px; margin: 0 auto; padding: 15px;">
    <NCard
      size="small"
      :segmented="{ content: 'hard' }"
      :title="paperTitle"
    >
      <BriefInfo
        :brief-info="briefInfo"
      />
      <LabelInfo
        :label-list="labelList"
      />
      <div>
        <ModuleContent
          :module-data="moduleData"
          hide-resource-list
        />
      </div>
    </NCard>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRoute } from 'vue-router';

  import ModuleContent from '@/views/material/preview/components/ModuleContent.vue';
  import PageLoading from '@/components/PageLoading/index.vue';
  import BriefInfo from './components/BriefInfo.vue';
  import LabelInfo from '@/views/teaching/preview/components/LabelInfo.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { paperComposeTypeBriefName } from '@/enumerators/compose-paper-way-map.js';

  import { getPaperDetail } from '@/api/paper.js';

  const route = useRoute();
  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  const paperId = route.query.id;
  const moduleData = ref({});

  const paperTitle = ref(null);
  const briefInfo = reactive({
    creator: '',
    num: 0,
    score: '00.00',
    formType: '',
    updateTime: 0
  });
  const labelList = ref([]);

  function getExerciseIds(paperId) {
    loading.value = true;
    getPaperDetail({
      'Paper[id]': paperId
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const {
          label_list,
          paper_exercises,
          admin_username,
          paper_exercises_num,
          score,
          type,
          update_time,
          title
        } = data;
        if (Array.isArray(paper_exercises)) {
          moduleData.value = {
            title: '',
            type_source_list: [
              {
                data_type: resourceEnum.PAPER_RESOURCE_TYPE,
                source_id: paper_exercises,
                title: ''
              }
            ]
          };
        }
        if (Array.isArray(label_list)) {
          labelList.value = label_list.map(item => item.title);
        }
        Object.assign(briefInfo, {
          creator: admin_username,
          num: paper_exercises_num,
          score,
          formType: paperComposeTypeBriefName[type],
          updateTime: update_time * 1000
        });
        paperTitle.value = title;
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  getExerciseIds(paperId);
</script>